<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron preguntas"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :toolbar-options="toolbarOptions"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="pregunta_checklist_ot.idpregunta_checklist_ot"
      @click-toolbar-option="clickToolbarOption"
    >
      <template
        #listItem="slotProps"
      >
        <pregunta-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :estado-checklist-pendiente="estadoChecklistPendiente"
          :grado-anomalia-correcto="gradoAnomaliaCorrecto"
          :grados-anomalia-no-procede="gradosAnomaliaNoProcede"
          :grados-anomalia-desconocido="gradosAnomaliaDesconocido"
          @click-grado-anomalia-resto="clickGradoAnomaliaResto"
          @click-grado-anomalia-error="clickGradoAnomaliaError"
          @click-anomalias="clickAnomalias"
          @click-valor="clickValor"
          @click-notas-internas-observaciones="clickNotasInternasObservaciones"
          @click-borrar-observaciones="clickBorrarObservaciones"
        />
      </template>
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
        :idchecklist-ot="routeParams.idchecklist_ot"
        :idparte-trabajo-matsist="idsParaVolerAtras.idparte_trabajo_matsist"
        :idsubsis="idsParaVolerAtras.idsubsis"
      />
    </b10-list>
    <pregunta-list-valor
      :showing.sync="showingDialogs.preguntaValor"
      :item="preguntaActualItem"
      :readonly="!estadoChecklistPendiente"
      :index="preguntaActualIndex"
      @set-valor-pregunta="setValorPregunta"
    />
    <pregunta-list-notas-internas-observaciones
      :showing.sync="showingDialogs.preguntaNotasInternasObservaciones"
      :item="preguntaActualItem"
      :readonly="!estadoChecklistPendiente"
      :index="preguntaActualIndex"
      @set-notas-internas-observaciones="setNotasInternasObservaciones"
    />
    <pregunta-list-legend
      :showing.sync="showingDialogs.legends"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './PreguntaListData'
import PreguntaListItem from './components/PreguntaListItem'
import PreguntaListValor from './components/PreguntaListValor'
import PreguntaListNotasInternasObservaciones from './components/PreguntaListNotasInternasObservaciones'
import PreguntaListLegend from './components/PreguntaListLegend'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'
import { PREGUNTA_CHECKLIST, CHECKLIST, GRADO_ANOMALIA } from '@/utils/consts'
import { get } from 'vuex-pathify'
import { NextRouteBack } from '@/router'
import _ from '@/utils/lodash'

const pageStoreName = 'pagesOfflinePreguntaList'

export default {
  components: {
    PreguntaListItem, PreguntaListLegend, PreguntaListValor, PreguntaListNotasInternasObservaciones, VolverParteChecklistMenuItems,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        marcarTodoCorrecto: {
          title: 'Marcar todo correcto',
          visible: true,
          icon: 'checkAll'
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        }
      },
      showingDialogs: {
        legends: false,
        preguntaValor: false,
        preguntaNotasInternasObservaciones: false,
      },
      checklistOt: {},
      gradoAnomaliaCorrecto: {},
      gradosAnomaliaNoProcede: [],
      gradosAnomaliaDesconocido: [],
      gradosAnomaliaConAnomalias: [],
      preguntaActualItem: {},
      preguntaActualIndex: null,
      idsParaVolerAtras: {
        idparte_trabajo_matsist: null,
        idsubsis: null,
      },
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    usuarioIdtecnico: get('usuario/idtecnico'),
    estadoChecklistPendiente () {
      return this.checklistOt.checklist_ot && this.checklistOt.checklist_ot.estado === CHECKLIST.estados.pendiente
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.checklistOt = await this.$offline.checklistOt.row(this.routeParams.idchecklist_ot)
      if (this.routeParams.idgrupo_checklist_ot) {
        // grupos de preguntas generales o de subsistemas
        const grupoChecklistOt = await this.$offline.grupoChecklistOt.row(this.routeParams.idgrupo_checklist_ot)
        this.title = `Preguntas "${grupoChecklistOt.grupo_checklist_ot.descripcion}"`
      } else {
        // grupos de preguntas de material
        const ordenTrabajoMatsist = await this.$offline.ordenTrabajoMatsist.row(this.routeParams.idorden_trabajo_matsist)
        const parteTrabajoMatsist = await this.$offline.parteTrabajoMatsist.selectParteTrabajoMatsistOrdenTrabajoMatsist(
          this.routeParams.idorden_trabajo_matsist
        )
        this.idsParaVolerAtras.idparte_trabajo_matsist = parteTrabajoMatsist.parte_trabajo_matsist.idparte_trabajo_matsist
        const materialSistema = await this.$offline.materialSistema.row(ordenTrabajoMatsist.orden_trabajo_matsist.idmaterial_sistema)
        this.idsParaVolerAtras.idsubsis = materialSistema.material_sistema.idsubsis
        this.title = `Preguntas "${materialSistema.material_sistema.descripcion}"`
      }
      this.toolbarOptions.marcarTodoCorrecto.visible = (
        this.estadoChecklistPendiente &&
        this.hasViewPerm(this.permissions.partes.marcarPreguntasTodoCorrecto)
      )
      // grados de anomalias
      const gradosAnomalia = await Data.selectGradoAnomalia(this)
      for (const grado of gradosAnomalia) {
        if (grado.clasificacion === GRADO_ANOMALIA.clasificaciones.correcto) {
          this.gradoAnomaliaCorrecto = grado
        } else if (grado.clasificacion === GRADO_ANOMALIA.clasificaciones.noProcede) {
          this.gradosAnomaliaNoProcede.push(grado)
        } else if (grado.clasificacion === GRADO_ANOMALIA.clasificaciones.desconocido) {
          this.gradosAnomaliaDesconocido.push(grado)
        } else {
          this.gradosAnomaliaConAnomalias.push(grado)
        }
      }
      this.pageLoaded = true
    },
    gradoAnomaliaFormValidate () {
      this.$refs.gradoAnomaliaForm.validate()
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectPreguntaChecklistOt(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idchecklist_ot,
          this.routeParams.idgrupo_checklist_ot,
          this.routeParams.idorden_trabajo_matsist
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectPreguntaChecklistOtRows(this, dirty.modified),
          dirty,
          'pregunta_checklist_ot.idpregunta_checklist_ot'
        )
      } finally {
        this.loadingData = false
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.marcarTodoCorrecto) {
        const res = await this.$alert.showConfirm(
          `¿Deseas marcar todas las preguntas no contestadas como correctas?`)
        if (res) {
          this.$loading.showManual('Marcando todo correcto')
          try {
            if (this.routeParams.idorden_trabajo_matsist) {
              await this.$offline.ordenTrabajoMatsist.updateMaterialContestadoCorrecto(
                this.routeParams.idparte_trabajo,
                this.routeParams.idchecklist_ot,
                this.routeParams.idorden_trabajo_matsist,
                this.usuarioIdtecnico
              )
              await this.setDirtyParteTrabajoMatSist(this.routeParams.idorden_trabajo_matsist)
            } else {
              await this.$offline.grupoChecklistOt.updateGrupoContestadoCorrecto(
                this.routeParams.idgrupo_checklist_ot,
                this.usuarioIdtecnico,
                this.routeParams.idparte_trabajo
              )
            }
          } finally {
            this.$loading.hide()
          }
          this.resetList()
        }
      }
    },
    async cleanObservaciones (index) {
      await this.setStoreProperty(`items@[${index}].pregunta_checklist_ot.observaciones`, null)
      // sync
      const pregunta = _.cloneDeep(this.items[index].pregunta_checklist_ot)
      pregunta.observaciones = null
      await this.$offline.preguntaChecklistOt.updateSync(pregunta)
    },
    async setGradoAnomaliaItem (index, gradoAnomalia) {
      // UI (grado/resultado de la pregunta e inner join con grado_anomalia)
      await this.setStoreProperty(
        `items@[${index}].pregunta_checklist_ot.idgrado_anomalia`,
        gradoAnomalia.idgrado_anomalia
      )
      if (gradoAnomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.correcto) {
        await this.setStoreProperty(
          `items@[${index}].pregunta_checklist_ot.resultado`,
          PREGUNTA_CHECKLIST.resultado.ok
        )
      } else if (gradoAnomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.noProcede) {
        await this.setStoreProperty(
          `items@[${index}].pregunta_checklist_ot.resultado`,
          PREGUNTA_CHECKLIST.resultado.noProcede
        )
      } else {
        await this.setStoreProperty(
          `items@[${index}].pregunta_checklist_ot.resultado`,
          PREGUNTA_CHECKLIST.resultado.conAnomalias
        )
      }
      await this.setStoreProperty(`items@[${index}].grado_anomalia`, gradoAnomalia)
      // sync
      const pregunta = _.cloneDeep(this.items[index].pregunta_checklist_ot)
      pregunta.idtecnico = this.usuarioIdtecnico
      await this.$offline.preguntaChecklistOt.updateSync(pregunta)
      if (this.routeParams.idorden_trabajo_matsist) {
        await this.setDirtyParteTrabajoMatSist(this.routeParams.idorden_trabajo_matsist)
      }
      await this.reloadPregunta(this.items[index].pregunta_checklist_ot.idpregunta_checklist_ot)
    },
    async clickGradoAnomaliaResto (data) {
      // casos: ok, no procede y desconocido
      if (data.item.cantAnomalias === 0) {
        await this.setGradoAnomaliaItem(data.index, data.gradoAnomalia)
      } else {
        const res = await this.$alert.showConfirm(
          `Para poder marcar la pregunta como "${data.gradoAnomalia.descripcion}" es necesario eliminar
          sus anomalías (${data.item.cantAnomalias}). ¿Deseas continuar?`)
        if (res) {
          await this.$offline.preguntaChecklistOt.deleteAnomaliasSync(
            data.item.pregunta_checklist_ot.idpregunta_checklist_ot,
            this.usuarioIdtecnico
          )
          await this.cleanObservaciones(data.index)
          await this.setGradoAnomaliaItem(data.index, data.gradoAnomalia)
        }
      }
      if (this.routeParams.idorden_trabajo_matsist) {
        await this.setDirtyParteTrabajoMatSist(this.routeParams.idorden_trabajo_matsist)
      }
    },
    async clickGradoAnomaliaError (data) {
      const paramsQuery = {}
      if (this.gradosAnomaliaConAnomalias.length === 1) {
        paramsQuery.idgrado_anomalia = this.gradosAnomaliaConAnomalias[0].idgrado_anomalia
      }
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'offline__anomalia-add',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.routeParams.idchecklist_ot,
          idgrupo_checklist_ot: data.item.pregunta_checklist_ot.idgrupo_checklist_ot,
          idpregunta_checklist_ot: data.item.pregunta_checklist_ot.idpregunta_checklist_ot,
          nextRoute: NextRouteBack(),
        },
        query: paramsQuery
      })
      if (this.routeParams.idorden_trabajo_matsist) {
        await this.setDirtyParteTrabajoMatSist(this.routeParams.idorden_trabajo_matsist)
      }
    },
    clickAnomalias (data) {
      this.rememberState = true
      this.rememberScroll = true
      // para que luego de añadir vuelva a la lista (y no redirija al AnomaliaView)
      this.$appRouter.push({
        name: 'offline__anomalia-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.routeParams.idchecklist_ot,
        },
        query: {
          idgrupo_checklist_ot: data.item.pregunta_checklist_ot.idgrupo_checklist_ot,
          idpregunta_checklist_ot: data.item.pregunta_checklist_ot.idpregunta_checklist_ot,
        },
      })
    },
    clickValor (data) {
      this.preguntaActualItem = data.item
      this.preguntaActualIndex = data.index
      this.showingDialogs.preguntaValor = true
    },
    async setValorPregunta (data) {
      const pregunta = _.cloneDeep(this.items[data.index].pregunta_checklist_ot)
      pregunta.idtecnico = this.usuarioIdtecnico
      if (data.item.agrupacion_pregunta_ot.idagrupacion_pregunta_ot) {
        // actualizar valores de cada valor_columna_ot
        for (const valorColumna of data.item.valoresColumna) {
          if (data.formData[valorColumna.idvalor_columna_ot]) {
            await this.$offline.valorColumnaOt.updateSync({
              idvalor_columna_ot: valorColumna.idvalor_columna_ot,
              valor: data.formData[valorColumna.idvalor_columna_ot],
            })
          } else {
            await this.$offline.valorColumnaOt.updateSync({
              idvalor_columna_ot: valorColumna.idvalor_columna_ot,
              valor: null,
            })
          }
        }
      } else {
        // actualizar valor de pregunta_checklist_ot
        pregunta.valor = data.formData.valor
      }
      await this.$offline.preguntaChecklistOt.updateSync(pregunta)
      await this.reloadPregunta(pregunta.idpregunta_checklist_ot)
    },
    clickNotasInternasObservaciones (data) {
      this.preguntaActualItem = data.item
      this.preguntaActualIndex = data.index
      this.showingDialogs.preguntaNotasInternasObservaciones = true
    },
    async setNotasInternasObservaciones (data) {
      const pregunta = _.cloneDeep(this.items[data.index].pregunta_checklist_ot)
      pregunta.idtecnico = this.usuarioIdtecnico
      // actualizar valor de pregunta_checklist_ot
      pregunta.notas_internas = data.formData.notas_internas
      pregunta.observaciones = data.formData.observaciones
      await this.$offline.preguntaChecklistOt.updateSync(pregunta)
      await this.reloadPregunta(this.items[data.index].pregunta_checklist_ot.idpregunta_checklist_ot)
    },
    async setDirtyParteTrabajoMatSist (idordenTrabajoMatsist) {
      if (idordenTrabajoMatsist) {
        const parteTrabajoMatsist = await Data.selectParteTrabajoMatsist(this, idordenTrabajoMatsist)
        for (const parteMaterial of parteTrabajoMatsist) {
          await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsist, parteMaterial.parte_trabajo_matsist.idparte_trabajo_matsist)
        }
      }
    },
    async reloadPregunta (idpreguntaChecklistOt) {
      await this.$dirty.modified(this.$dirty.ENTITIES.local.preguntaChecklistOt, idpreguntaChecklistOt)
      await this.reloadDirtyItems()
    },
    async clickBorrarObservaciones(idpreguntaChecklistOt){
      await Data.deleteObservacionesPreguntaChecklistOT(this, idpreguntaChecklistOt)
      await this.reloadPregunta(idpreguntaChecklistOt)
    }
  },
}
</script>
