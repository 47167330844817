import { likeRegExp } from '@/offline/database'

export default {
  async _addSubqueriesPreguntaChecklistOt (Vue, rows) {
    const tables = Vue.$offline.db.tables
    for (let row of rows) {
      row.cantAnomalias = (await Vue.$offline.db
        .select(Vue.$offline.db.fn.count(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot).as('count'))
        .from(tables.tanomalia_checklist_ot)
        .where(
          Vue.$offline.db.op.and(
            tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(row.pregunta_checklist_ot.idpregunta_checklist_ot),
            tables.tanomalia_checklist_ot.estado.gt(0),
          )
        )
        .exec())[0].count
      if (row.pregunta_checklist_ot.idagrupacion_pregunta_ot) {
        row.valoresColumna = await Vue.$offline.db
          .select()
          .from(tables.valor_columna_ot)
          .where(
            Vue.$offline.db.op.and(
              tables.valor_columna_ot.estado.gt(0),
              tables.valor_columna_ot.idpregunta_checklist_ot.eq(row.pregunta_checklist_ot.idpregunta_checklist_ot),
              tables.valor_columna_ot.idagrupacion_pregunta_ot.eq(row.pregunta_checklist_ot.idagrupacion_pregunta_ot)
            )
          )
          .orderBy(tables.valor_columna_ot.orden)
          .exec()
      }
    }
    return rows
  },
  _selectPreguntaChecklistOtBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist,
        tables.pregunta_checklist_ot.idpregunta_checklist.eq(tables.pregunta_checklist.idpregunta_checklist)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .leftOuterJoin(
        tables.agrupacion_pregunta_ot,
        tables.pregunta_checklist_ot.idagrupacion_pregunta_ot.eq(tables.agrupacion_pregunta_ot.idagrupacion_pregunta_ot)
      )
      .leftOuterJoin(
        tables.grado_anomalia,
        tables.pregunta_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
      )
      .leftOuterJoin(
        tables.orden_trabajo_matsist,
        tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .leftOuterJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
  },
  async selectPreguntaChecklistOt (Vue, filter, search, sorter, page, idchecklistOt, idgrupoChecklistOt, idordenTrabajoMatSist) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.pregunta_checklist_ot.descripcion.match(reQ),
          tables.pregunta_checklist_ot.alias.match(reQ),
        )
      )
    }
    let whereGrupoOMaterial = []
    if (idgrupoChecklistOt) {
      whereGrupoOMaterial.push(tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt))
    } else {
      whereGrupoOMaterial.push(tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatSist))
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
        tables.pregunta_checklist_ot.estado.gt(0),
        ...whereSearch,
        ...whereFilter,
        ...whereGrupoOMaterial
      )
    )
    const rows = await this._selectPreguntaChecklistOtBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
      .orderBy(tables.grupo_checklist_ot.orden, tables.pregunta_checklist_ot.orden)
      .exec()
    return [
      await this._addSubqueriesPreguntaChecklistOt(Vue, rows),
      []
    ]
  },
  async selectPreguntaChecklistOtRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    const rows = await this._selectPreguntaChecklistOtBase(Vue)
      .where(tables.pregunta_checklist_ot.idpregunta_checklist_ot.in(pks))
      .exec()
    return (await this._addSubqueriesPreguntaChecklistOt(Vue, rows))
  },
  selectGradoAnomalia (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.grado_anomalia)
      .where(tables.grado_anomalia.estado.gt(0))
      .orderBy(tables.grado_anomalia.orden)
      .exec()
  },
  selectParteTrabajoMatsist (Vue, idordenTrabajoMatSist) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.orden_trabajo_matsist.estado.gt(0),
          tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(idordenTrabajoMatSist),
        )
      )
      .exec()
  },
  async deleteObservacionesPreguntaChecklistOT(Vue, idpreguntaChecklistOt) {
    await Vue.$offline.preguntaChecklistOt.updateSync({
      idpregunta_checklist_ot: idpreguntaChecklistOt,
      observaciones: null,
    })
  }
}
